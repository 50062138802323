.header {
  width: calc(100% - 200px);
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: flex-end;
  align-items: start;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 60px;
  margin-left: 200px;
}

.header h1 {
  margin: 0;
}

.header .logoutButton {
  background: #152c70;
  color: #fff;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: 0.01em;
  text-align: center;
  height: 36px;
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 20px;
}

.header .logout:hover {
  background-color: #555;
}

.userWalletAddress {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #1F384C;
}

.userInfoWihLogout {
    display: flex;
    gap: 10px;
    align-items: center;
}