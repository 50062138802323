.organizationFormContainer {
}
.organizationForm {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  .formGroup {
    width: calc(50% - 10px);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    gap: 5px;
    :global {
      label {
        font-family: Poppins;
        font-size: 13px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #6c737f;
      }

      input,
      select {
        height: 46px;
        padding: 11px 12px 11px 16px;
        border-radius: 6px;
        border: 1px solid #cbd2e0;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #000000;
      }

      select {
        cursor: pointer;
      }

      select option {
        appearance: none;
        background-color: #fff !important;
        border-radius: 4px;
        padding: 8px;
        span {
            background-color: #fff !important;
        }
        &:hover {
          background-color: #182a5c !important;
        }
      }
    }
  }
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button {
    width: 120px;
    height: 40px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    border-radius: 6px;
}

.cancelButton {
    border: 1px solid #152C70;
    background-color: #fff;    
    color: #152C70;
}

.submitButton {    
    background-color: #152C70;
    color: #FFF;
}