/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
html, * {
  box-sizing: border-box;
}

.App {
  display: flex;
  height: 100vh;
  position: relative;
  flex-direction: column;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  width: 100%;
}

.login-form {
  background-color: white;
  padding: 40px;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form h2 {
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.login-form-text {
  color: #696969;
  font-size:x-small;
}

.form-group input {
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 15px;
  
}

.terms {
  color: #696969;
  font-size: smaller;
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
  margin-top: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.login-form button {
  background-color: grey;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  width: 300px;
}

.login-form button:hover {
  background-color: #555;
}

.main-content {
  flex-grow: 1;
  padding: 0;
  background-color: #f5f6f8;
  margin-left: 200px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 50%;
  max-width: 600px;
  box-sizing: border-box;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  line-height: 1;
}

.main-page-content {
  padding: 20px;
}

.notes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.note-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  background-color: #f9f9f9;
}

.notes-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1em;
  font-family: 'Arial', sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.notes-table thead tr {
  background-color: #C5CEE2;
  color: #152c70;
  text-align: left;
}

.notes-table th,
.notes-table td {
  padding: 12px 15px;
}

.notes-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.notes-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.notes-table tbody tr:hover {
  background-color: #c4c4c4;
}