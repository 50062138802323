.button {
    background: #152C70;
    color: #FFF;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    letter-spacing: 0.01em;
    text-align: center;
    height: 36px;
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 20px;
}

.organizationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modal{
    .modalHeader {
        background-color: #C5CEE2;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.01em;
        display: flex;
        width: 100%;
        justify-content: center;
    }
    :global {
        .modal-content {
            padding: 0;
        }
    }
}  