.sidebar {
  width: 200px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;  
  padding: 20px 10px;
  padding-top: 0;
  position: absolute;
  height: 100%;
}


/* .sidebar h2 {
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 20px;
} */

.sidebar a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
  cursor: pointer;
}

.sidebar a:hover {
  background-color: #7e73ff;
  width: 100%;
}

.menuItem {
  list-style: none;
  color: #fff;
}

.sidebarHeader {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

.menuItemLink {
  display: flex;
  background: none;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  width: 100%;
  gap: 20px;
  padding: 10px;
  border-radius: 8px;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.menuItemLink:hover, .menuItemLink.isActive {
    background: #FFFFFF;
    background: linear-gradient(180deg, rgba(126, 115, 255, 0.35) 0%, rgba(91, 77, 255, 0.35) 100%);
}

.menu {
  margin: 0;
  padding: 0;
  width: 100%;
}
