.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .userInfo {
    margin-bottom: 20px;
  }
  
  .closeButton {
    background: #152c70;
    color: #fff;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    letter-spacing: 0.01em;
    text-align: center;
    height: 36px;
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 20px;
    border: none;
  }