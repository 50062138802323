.pageHeaderContainer {
    background-color: #f5f6f8;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.breadcrumpsContainer {
    display: flex;
    gap: 20px;
    align-items: center;
}

.breadcrumpsContainer .breadcrumpsItem {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #6C737F;
}

.breadcrumpsContainer .seperator {
    color: #152C70;
}

.breadcrumpsContainer .activePageName {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    color: #152C70;
    border-bottom: 2px solid #152C70;
    text-transform: capitalize;
}

.pageTitleContainer {
    display: flex;
    justify-content: space-between;
}

.pageTitleContainer .pageTitle {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #152C70;
    text-transform: capitalize;
}

.pageTitleContainer .actionContainer {

}

